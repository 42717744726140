import React from "react";
import { withRouter } from "react-router-dom";
import LoaderComponent from "./LoaderComponent"
const WrapperComponent = (props) => {
  return (
    <React.Fragment>
      <div className="h-100">
        {props.children}
      </div>

    </React.Fragment>
  );
};
const withLoader = LoaderComponent(WrapperComponent)
export default withRouter(withLoader);
