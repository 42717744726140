import React from "react";
import {Route,Redirect} from "react-router-dom";
import { GlobalContext } from "../Context/Context"
const RootRouteGuard = ({ xComponent: Component, ...xProps }) => {

    const context = React.useContext(GlobalContext)
    const loginData = context.login;

    return (
        <Route
            {...xProps}
            render={routeParams => {
                const pathName = routeParams.match.path;
                if (loginData.isLoggedIn) {
                    if (pathName === '/login' || pathName === '/') {
                        return <Redirect to="/home" />;
                    }
                    return <Component {...routeParams} key={routeParams.match.url} />;
                }
                if (pathName === '/' || pathName === '/login') {
                    return <Component {...routeParams} key={routeParams.match.url} />;
                }
                return <Redirect to="/login" />;
            }}
        />
    );
};
export default RootRouteGuard