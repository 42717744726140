import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import React, { lazy, Suspense } from "react";
import RootRouteGuard from "./RootRouteGuard"
import WrapperComponent from "../Modules/General/WrapperComponent";
import { RENDER_URL } from "../Utils/Urls";
import ContextStore from "../Context/ContextStore"
import { GlobalContext } from "../Context/Context"

const LoginContainer = lazy(() => import("../Modules/Login/LoginContainer"));
const HomeContainer = lazy(() => import("../Modules/Home/HomeContainer"));
const MeetingContainer = lazy(() => import("../Modules/Meeting/MeetingMainContainer"));
const AppsContainer = lazy(() => import("../Modules/Apps/AppsContainer"));
const DemoMeetingContainer = lazy(()=> import("../Modules/DemoMeeting/DemoMeetingContainer"));



const Routes = ({ history }) => {

    return (
      
        <Router basename="/">
            <Suspense fallback={<div className="displayNone"></div>}>
                <ContextStore>
                    <WrapperComponent history={history}>
                        <Switch>
                            <RootRouteGuard exact path="/" xComponent={LoginContainer} />
                            <RootRouteGuard exact path={RENDER_URL.LOGIN_URL} xComponent={LoginContainer} />
                            <RootRouteGuard exact path={RENDER_URL.HOME_URL} xComponent={HomeContainer} />
                            <RootRouteGuard exact path={RENDER_URL.MEETING_URL} xComponent={MeetingContainer} /> 
                            <RootRouteGuard exact path={RENDER_URL.APP_URL} xComponent={AppsContainer} />   
                            <RootRouteGuard exact path={RENDER_URL.HUBSPOT_REDIRECT} xComponent={AppsContainer} />  
                            <RootRouteGuard exact path={RENDER_URL.DEMO_MEETING} xComponent={DemoMeetingContainer} /> 
                            <Route
                                path={"*"}
                                render={(props) => <Redirect to="/home" {...props} />}
                            />
                            

                        </Switch>
                    </WrapperComponent>
                </ContextStore>
            </Suspense>
        </Router>

    );


}


export default Routes;