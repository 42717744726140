import React from 'react'
import { GlobalContext } from "../../Context/Context"
const LoaderComponent = (HocComponent) => {
    const Loader = (props) => {
        const context = React.useContext(GlobalContext)
        const showLoader = context.common.isLoading
        return (
            <div className="h-100">
                {
                    showLoader && <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                }
                <HocComponent {...props} />
            </div>
        );
    };
    return Loader;
};
export default LoaderComponent;