import {
    START_LOADING,
    STOP_LOADING,
    LAST_COMPLETED_MID,
    LAST_UPCOMING_MID
} from "../Utils/Constants";

export const commonInitialState = {
    isLoading: false,
    cMID: 0,
    uMID: 0
};

export const commonReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return commonInitialState
    }

    switch (action.type) {

        case START_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case STOP_LOADING:
            return {
                ...state,
                isLoading: false
            }
        case LAST_COMPLETED_MID:
            return {
                ...state, cMID: action.payload
            }
        case LAST_UPCOMING_MID:
            return {
                ...state, uMID: action.payload
            }
        default:
            return state;

    }

};
