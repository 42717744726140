import { LOGIN_SUCCESS, LOGOUT,WORK_FLOW_LIST } from "../Utils/Constants";
import { ManageLocalStorage } from "../Services/Localstorage"

let user = ManageLocalStorage.get("_user") ? JSON.parse(ManageLocalStorage.get("_user")) : null;
export const loginInitialState = {
    user: user,
    isLoggedIn: ManageLocalStorage.get("token") ? true : false
};

export const LoginReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return loginInitialState
    }
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        case WORK_FLOW_LIST:
            return {
                ...state,
                workflowlist:action.payload
            };

        default:
            return state;
    }
};
