export const BASE_URL = "http://localhost:5000"

/**
 * API and Render url defined
 */
export const RENDER_URL = {
    HOME_URL: '/home',
    MEETING_URL:'/meeting',
    APP_URL:'/myapps',
    LOGIN_URL: "/login",
    HUBSPOT_REDIRECT:"/hubspot",
    DEMO_MEETING:"/demo"
};


export const API_URL = {
    LOGIN: "/login",
    GETWORKFLOW:"/workflow",
    ADDWORKFLOW:"/workflow",
    GETWORKFLOW_DEFINITION:"/workflowdefinition",
    HUBSPOT_CONNECT:"/hubspot",
    SLACK_CONNECT:"/slack",
    UPCOME_MEETING:"/meeting",
    COMPLETED_MEETING:"/meeting/getcompletedlist",
    DRIVE_AUTH:"/driveauth",
    MEETING_TRANSCRIPTS:"/meeting/search",
    ZOOM_CONNECT:"/zoom"
}


export const SOCKET_URL = {
    SOCKET_IO_URL : process.env.REACT_APP_DEMO_URL
}
